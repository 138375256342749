import * as Types from "../../../graphql/graphql-generated.types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetUserProfileStatusForSignInQueryVariables = Types.Exact<{
  input: Types.GetUserProfileStatusInput;
}>;

export type GetUserProfileStatusForSignInQuery = {
  __typename?: "Query";
  getUserProfileStatus: {
    __typename?: "UserProfileStatus";
    exists: boolean;
    hasProfileWithThisClient: boolean;
  };
};

export type AuthenticateUserForSignInMutationVariables = Types.Exact<{
  authenticateUserInput: Types.AuthenticateUserInput;
  profilesPagination: Types.PaginationArgs;
  profilesFilter: Types.ProfileFilter;
}>;

export type AuthenticateUserForSignInMutation = {
  __typename?: "Mutation";
  authenticateUser:
    | { __typename?: "AuthUserFailed"; message: string }
    | {
        __typename?: "AuthUserSuccess";
        code: string;
        user: {
          __typename?: "User";
          firstName: string;
          lastName: string;
          profiles: {
            __typename?: "ProfilePaginatedResponse";
            data: Array<{
              __typename?: "Profile";
              isStaff: boolean;
              hasControlPanelAccess: boolean;
              membershipRole: string | null;
              client: {
                __typename?: "Client";
                uuid: string;
                company: string;
                subdomain: string;
                logoUrl: string | null;
              };
            }>;
          };
        };
      };
};

export type AuthenticateUserWithCodeForSignInMutationVariables = Types.Exact<{
  authenticateUserWithCodeInput: Types.AuthenticateUserWithCodeInput;
  profilesPagination: Types.PaginationArgs;
  profilesFilter: Types.ProfileFilter;
}>;

export type AuthenticateUserWithCodeForSignInMutation = {
  __typename?: "Mutation";
  authenticateUserWithCode:
    | { __typename?: "AuthUserFailed"; message: string }
    | {
        __typename?: "AuthUserSuccess";
        code: string;
        user: {
          __typename?: "User";
          firstName: string;
          lastName: string;
          profiles: {
            __typename?: "ProfilePaginatedResponse";
            data: Array<{
              __typename?: "Profile";
              isStaff: boolean;
              hasControlPanelAccess: boolean;
              membershipRole: string | null;
              client: {
                __typename?: "Client";
                uuid: string;
                company: string;
                subdomain: string;
                logoUrl: string | null;
              };
            }>;
          };
        };
      };
};

export type AuthenticatedUserFragment = {
  __typename?: "AuthUserSuccess";
  code: string;
  user: {
    __typename?: "User";
    firstName: string;
    lastName: string;
    profiles: {
      __typename?: "ProfilePaginatedResponse";
      data: Array<{
        __typename?: "Profile";
        isStaff: boolean;
        hasControlPanelAccess: boolean;
        membershipRole: string | null;
        client: {
          __typename?: "Client";
          uuid: string;
          company: string;
          subdomain: string;
          logoUrl: string | null;
        };
      }>;
    };
  };
};

export type GenerateCodeLoginForSignInMutationVariables = Types.Exact<{
  input: Types.GenerateCodeLoginInput;
}>;

export type GenerateCodeLoginForSignInMutation = {
  __typename?: "Mutation";
  generateCodeLogin: { __typename?: "SuccessResponse"; success: boolean };
};

export type LoginToControlPanelWithCodeMutationVariables = Types.Exact<{
  input: Types.LoginWithCodeInput;
}>;

export type LoginToControlPanelWithCodeMutation = {
  __typename?: "Mutation";
  loginWithCode: {
    __typename?: "AuthSuccess";
    accessToken: string;
    refreshToken: string;
  };
};

export type UpdatePasswordMutationVariables = Types.Exact<{
  input: Types.UpdatePasswordInput;
}>;

export type UpdatePasswordMutation = {
  __typename?: "Mutation";
  updatePassword: boolean;
};

export const AuthenticatedUserFragmentDoc = gql`
  fragment AuthenticatedUser on AuthUserSuccess {
    code
    user {
      firstName
      lastName
      profiles(pagination: $profilesPagination, filters: $profilesFilter) {
        data {
          client {
            uuid
            company
            subdomain
            logoUrl
          }
          isStaff
          hasControlPanelAccess
          membershipRole
        }
      }
    }
  }
`;
export const GetUserProfileStatusForSignInDocument = gql`
  query GetUserProfileStatusForSignIn($input: GetUserProfileStatusInput!) {
    getUserProfileStatus(getUserProfileStatusInput: $input) {
      exists
      hasProfileWithThisClient
    }
  }
`;

/**
 * __useGetUserProfileStatusForSignInQuery__
 *
 * To run a query within a React component, call `useGetUserProfileStatusForSignInQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileStatusForSignInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileStatusForSignInQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserProfileStatusForSignInQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserProfileStatusForSignInQuery,
    GetUserProfileStatusForSignInQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserProfileStatusForSignInQuery,
    GetUserProfileStatusForSignInQueryVariables
  >(GetUserProfileStatusForSignInDocument, options);
}
export function useGetUserProfileStatusForSignInLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserProfileStatusForSignInQuery,
    GetUserProfileStatusForSignInQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserProfileStatusForSignInQuery,
    GetUserProfileStatusForSignInQueryVariables
  >(GetUserProfileStatusForSignInDocument, options);
}
export type GetUserProfileStatusForSignInQueryHookResult = ReturnType<
  typeof useGetUserProfileStatusForSignInQuery
>;
export type GetUserProfileStatusForSignInLazyQueryHookResult = ReturnType<
  typeof useGetUserProfileStatusForSignInLazyQuery
>;
export type GetUserProfileStatusForSignInQueryResult = Apollo.QueryResult<
  GetUserProfileStatusForSignInQuery,
  GetUserProfileStatusForSignInQueryVariables
>;
export const AuthenticateUserForSignInDocument = gql`
  mutation AuthenticateUserForSignIn(
    $authenticateUserInput: AuthenticateUserInput!
    $profilesPagination: PaginationArgs!
    $profilesFilter: ProfileFilter!
  ) {
    authenticateUser(authenticateUserInput: $authenticateUserInput) {
      ... on AuthUserSuccess {
        ...AuthenticatedUser
      }
      ... on AuthUserFailed {
        message
      }
    }
  }
  ${AuthenticatedUserFragmentDoc}
`;
export type AuthenticateUserForSignInMutationFn = Apollo.MutationFunction<
  AuthenticateUserForSignInMutation,
  AuthenticateUserForSignInMutationVariables
>;

/**
 * __useAuthenticateUserForSignInMutation__
 *
 * To run a mutation, you first call `useAuthenticateUserForSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateUserForSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateUserForSignInMutation, { data, loading, error }] = useAuthenticateUserForSignInMutation({
 *   variables: {
 *      authenticateUserInput: // value for 'authenticateUserInput'
 *      profilesPagination: // value for 'profilesPagination'
 *      profilesFilter: // value for 'profilesFilter'
 *   },
 * });
 */
export function useAuthenticateUserForSignInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthenticateUserForSignInMutation,
    AuthenticateUserForSignInMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AuthenticateUserForSignInMutation,
    AuthenticateUserForSignInMutationVariables
  >(AuthenticateUserForSignInDocument, options);
}
export type AuthenticateUserForSignInMutationHookResult = ReturnType<
  typeof useAuthenticateUserForSignInMutation
>;
export type AuthenticateUserForSignInMutationResult =
  Apollo.MutationResult<AuthenticateUserForSignInMutation>;
export type AuthenticateUserForSignInMutationOptions =
  Apollo.BaseMutationOptions<
    AuthenticateUserForSignInMutation,
    AuthenticateUserForSignInMutationVariables
  >;
export const AuthenticateUserWithCodeForSignInDocument = gql`
  mutation AuthenticateUserWithCodeForSignIn(
    $authenticateUserWithCodeInput: AuthenticateUserWithCodeInput!
    $profilesPagination: PaginationArgs!
    $profilesFilter: ProfileFilter!
  ) {
    authenticateUserWithCode(
      authenticateUserWithCodeInput: $authenticateUserWithCodeInput
    ) {
      ... on AuthUserSuccess {
        ...AuthenticatedUser
      }
      ... on AuthUserFailed {
        message
      }
    }
  }
  ${AuthenticatedUserFragmentDoc}
`;
export type AuthenticateUserWithCodeForSignInMutationFn =
  Apollo.MutationFunction<
    AuthenticateUserWithCodeForSignInMutation,
    AuthenticateUserWithCodeForSignInMutationVariables
  >;

/**
 * __useAuthenticateUserWithCodeForSignInMutation__
 *
 * To run a mutation, you first call `useAuthenticateUserWithCodeForSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateUserWithCodeForSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateUserWithCodeForSignInMutation, { data, loading, error }] = useAuthenticateUserWithCodeForSignInMutation({
 *   variables: {
 *      authenticateUserWithCodeInput: // value for 'authenticateUserWithCodeInput'
 *      profilesPagination: // value for 'profilesPagination'
 *      profilesFilter: // value for 'profilesFilter'
 *   },
 * });
 */
export function useAuthenticateUserWithCodeForSignInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthenticateUserWithCodeForSignInMutation,
    AuthenticateUserWithCodeForSignInMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AuthenticateUserWithCodeForSignInMutation,
    AuthenticateUserWithCodeForSignInMutationVariables
  >(AuthenticateUserWithCodeForSignInDocument, options);
}
export type AuthenticateUserWithCodeForSignInMutationHookResult = ReturnType<
  typeof useAuthenticateUserWithCodeForSignInMutation
>;
export type AuthenticateUserWithCodeForSignInMutationResult =
  Apollo.MutationResult<AuthenticateUserWithCodeForSignInMutation>;
export type AuthenticateUserWithCodeForSignInMutationOptions =
  Apollo.BaseMutationOptions<
    AuthenticateUserWithCodeForSignInMutation,
    AuthenticateUserWithCodeForSignInMutationVariables
  >;
export const GenerateCodeLoginForSignInDocument = gql`
  mutation GenerateCodeLoginForSignIn($input: GenerateCodeLoginInput!) {
    generateCodeLogin(generateCodeLoginInput: $input) {
      success
    }
  }
`;
export type GenerateCodeLoginForSignInMutationFn = Apollo.MutationFunction<
  GenerateCodeLoginForSignInMutation,
  GenerateCodeLoginForSignInMutationVariables
>;

/**
 * __useGenerateCodeLoginForSignInMutation__
 *
 * To run a mutation, you first call `useGenerateCodeLoginForSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateCodeLoginForSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateCodeLoginForSignInMutation, { data, loading, error }] = useGenerateCodeLoginForSignInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateCodeLoginForSignInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateCodeLoginForSignInMutation,
    GenerateCodeLoginForSignInMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateCodeLoginForSignInMutation,
    GenerateCodeLoginForSignInMutationVariables
  >(GenerateCodeLoginForSignInDocument, options);
}
export type GenerateCodeLoginForSignInMutationHookResult = ReturnType<
  typeof useGenerateCodeLoginForSignInMutation
>;
export type GenerateCodeLoginForSignInMutationResult =
  Apollo.MutationResult<GenerateCodeLoginForSignInMutation>;
export type GenerateCodeLoginForSignInMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateCodeLoginForSignInMutation,
    GenerateCodeLoginForSignInMutationVariables
  >;
export const LoginToControlPanelWithCodeDocument = gql`
  mutation LoginToControlPanelWithCode($input: LoginWithCodeInput!) {
    loginWithCode(loginWithCodeInput: $input) {
      accessToken
      refreshToken
    }
  }
`;
export type LoginToControlPanelWithCodeMutationFn = Apollo.MutationFunction<
  LoginToControlPanelWithCodeMutation,
  LoginToControlPanelWithCodeMutationVariables
>;

/**
 * __useLoginToControlPanelWithCodeMutation__
 *
 * To run a mutation, you first call `useLoginToControlPanelWithCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginToControlPanelWithCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginToControlPanelWithCodeMutation, { data, loading, error }] = useLoginToControlPanelWithCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginToControlPanelWithCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginToControlPanelWithCodeMutation,
    LoginToControlPanelWithCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LoginToControlPanelWithCodeMutation,
    LoginToControlPanelWithCodeMutationVariables
  >(LoginToControlPanelWithCodeDocument, options);
}
export type LoginToControlPanelWithCodeMutationHookResult = ReturnType<
  typeof useLoginToControlPanelWithCodeMutation
>;
export type LoginToControlPanelWithCodeMutationResult =
  Apollo.MutationResult<LoginToControlPanelWithCodeMutation>;
export type LoginToControlPanelWithCodeMutationOptions =
  Apollo.BaseMutationOptions<
    LoginToControlPanelWithCodeMutation,
    LoginToControlPanelWithCodeMutationVariables
  >;
export const UpdatePasswordDocument = gql`
  mutation UpdatePassword($input: UpdatePasswordInput!) {
    updatePassword(updatePasswordInput: $input)
  }
`;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables
>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePasswordMutation,
    UpdatePasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePasswordMutation,
    UpdatePasswordMutationVariables
  >(UpdatePasswordDocument, options);
}
export type UpdatePasswordMutationHookResult = ReturnType<
  typeof useUpdatePasswordMutation
>;
export type UpdatePasswordMutationResult =
  Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables
>;
