import { Button, Grid, Icons } from "@pushpress/shared-components";
import { useTranslation } from "react-i18next";
import styles from "./OTPResetPassword.module.scss";

type ResetPasswordProps = {
  goToAccount: VoidFunction;
};

function SuccessUpdatingPassword({ goToAccount }: ResetPasswordProps) {
  const { t } = useTranslation("signin");

  return (
    <Grid container item xs={12} alignItems="center" justifyContent="center">
      <Icons.Success
        className={`mb-5 mt-1 ${styles.successIcon}`}
        width={72}
        height={72}
      />
      <Button
        text={t("successUpdatingPassword.goToAccount")}
        onClick={goToAccount}
        type="submit"
        size="large"
        fullWidth
      />
    </Grid>
  );
}

export default SuccessUpdatingPassword;
