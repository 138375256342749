import { Button, Divider, Grid, Text } from "@pushpress/shared-components";
import { useTranslation } from "react-i18next";

type AlternativeLoginProps = {
  goToResetPassword: VoidFunction;
  goToOTPLogin: VoidFunction;
};

function AlternativeLogin({
  goToOTPLogin,
  goToResetPassword,
}: AlternativeLoginProps) {
  const { t } = useTranslation("signin");

  return (
    <>
      <Grid container columnSpacing={3}>
        <Grid item xs={12}>
          <Button
            text={t("alternativeLogin.loginWithOTP")}
            buttonType="secondary"
            size="large"
            onClick={goToOTPLogin}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            text={t("alternativeLogin.resetPassword")}
            buttonType="secondary"
            size="large"
            onClick={goToResetPassword}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Text
            variant="heading-5"
            color="wolf-800"
            align="center"
            className="mb-1"
          >
            {t("alternativeLogin.footer.title")}
          </Text>
          <Text variant="body-lg" color="wolf-800" align="center">
            {t("alternativeLogin.footer.description")}
          </Text>
        </Grid>
      </Grid>
    </>
  );
}

export default AlternativeLogin;
