import { useEffect, useRef } from "react";

export function useFocusInput() {
  const inputDivRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputDivRef.current) {
      const inputRef = inputDivRef.current.querySelector("input");
      if (inputRef) {
        inputRef.focus();
      }
    }
  }, []);

  return inputDivRef;
}
