import {
  Button,
  Grid,
  PasswordController,
  Text,
  TextController,
} from "@pushpress/shared-components";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { useGenerateCodeLoginForSignInMutation } from "../../screens/signin/SignInForm/SignIn-generated.hooks";
import { defaultToastOptions } from "../../utils/toastUtils";
import { useFocusInput } from "../../hooks/useFocusInput";
import { extractError } from "../../utils";

export interface OTPResetPasswordForm {
  code: string;
  newPassword: string;
}

type OTPResetPasswordProps = {
  control: Control<OTPResetPasswordForm>;
  email?: string;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  loading?: boolean;
};

function OTPResetPassword({
  control,
  handleSubmit,
  loading,
  email,
}: OTPResetPasswordProps) {
  const { t } = useTranslation("signin");
  const inputRef = useFocusInput();

  const [generateCode, { loading: loadingGeneratingCode }] =
    useGenerateCodeLoginForSignInMutation({
      onError(err) {
        toast.error(extractError(err), defaultToastOptions);
      },
    });

  const sendCodeToEmail = useCallback(async () => {
    if (!email) return;

    await generateCode({
      variables: {
        input: {
          email,
          clientUuid: undefined, // clientUuid is deprecated,
        },
      },
    });

    toast.success(t("resetPassword.loginCodeSent"), defaultToastOptions);
  }, [generateCode, t, email]);

  return (
    <>
      <Text variant="body-lg" align="center" className="mb-3" color="wolf-800">
        {t("resetPassword.body", {
          email,
        })}
      </Text>
      <form onSubmit={handleSubmit} id="sign-in-account-check-step">
        <TextController
          control={control}
          label={t("form.fields.resetCode")}
          name="code"
          size="large"
          required
          ref={inputRef}
        />
        <PasswordController
          control={control}
          label={t("form.fields.newPassword")}
          name="newPassword"
          size="large"
          minLength={6}
          required
        />
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button
              type="button"
              text={t("form.resendCode")}
              buttonType="text"
              size="large"
              href="signup"
              onClick={sendCodeToEmail}
              loading={loadingGeneratingCode}
            />
          </Grid>
          <Grid item>
            <Button
              text={t("form.resetPassword")}
              type="submit"
              size="large"
              loading={loading}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default OTPResetPassword;
